<template>
  <v-app class="app-box">
    <v-banner
      single-line
      height="715"
      class="banner"
    >
      <img class="logo" src="@/assets/login/logo_bai.png" alt="" @click="$router.push('/')">
  
      <v-img
        src="https://h5.ophyer.cn/official_website/page/aigc/pc-banner-02.jpg"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <div class="banner-text">
          <div class="tit">
            <p>AIGC</p>
          </div>
          <p class="text">探索AI边界，提升AI技术水平 <br>发展大算力，支撑元宇宙建设及应用</p>
        </div>
      </v-img>
    </v-banner>


    <!-- 多模态体系 -->
    <v-card class="app-warp warp1 animate__animated animate__fadeInUp wow" data-wow-duration="1s" data-wow-offset="50" :flat="true">
      <div class="public-titles">
        <div><span></span><span></span><span></span><span></span></div>
        <p class="tit-z">多模态体系</p>
        <div><span></span><span></span><span></span><span></span></div>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/page/aigc/warp1-img-01-1.png" alt="">
      </div>
    </v-card>


    <v-card class="app-warp warp2 animate__animated animate__fadeInUp wow" data-wow-duration="1s" :flat="true">

      <ul class="video-text">
        <li v-for="(item,index) in warp2Content.list" :key="index" :class="warp2Content.active === index ? 'active' : ''">
          <video 
            :ref="'video'+index"
            :src="item.video"
            loop="loop"
            muted="true"
            preload="auto"
          ></video>
          <div class="text">
            <p>{{ item.title }}</p>
            <p>{{ item.text }}</p>
          </div>
        </li>
      </ul>
      <ul class="controllo-box">
        <li v-for="(item,index) in warp2Content.list" :key="index" @click="hanldeClick(index)" :class="warp2Content.active === index ? 'active' : ''">
          {{ item.title }}
          <span class="progress-bar"><span :ref="'progressBar'+index"></span></span>
        </li>
      </ul>

    </v-card>



    <!-- 行业及应用场景 -->
    <v-card class="app-warp warp3 animate__animated animate__fadeInUp wow" data-wow-duration="1s" :flat="true">
      <div class="public-titles">
        <div><span></span><span></span><span></span><span></span></div>
        <p class="tit-z">行业及应用场景</p>
        <div><span></span><span></span><span></span><span></span></div>
      </div>
      <div class="warp-box">
        <div class="c-item-1 box-shadow" v-for='(item,ind) in courseProducts' :key='ind' :class="[currentHover === (ind+1) ? 'c-item-hover':'', 'item'+(ind+1)]" @mouseover="currentHover =(ind+1);">
          <div class="c-item-cover">  
            <v-img :src='item.img' width="248">
            <div :class="['coverleft', 'coverleft'+(ind+1)]">
              <p>{{ item.sub }}</p>
              <p>{{ item.text }}</p>
            </div>  
            </v-img>    
          </div>        
        </div>
      </div>
    </v-card>



    <!-- 底部 -->
    <OfficialWebsiteFooter />
    <!-- 右侧悬浮 -->
    <RightSuspend />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "aigc",
  components: {
    OfficialWebsiteFooter: () => import('@/components/common/officialWebsiteFooter.vue'),
    RightSuspend: () => import('@/components/common/rightSuspend.vue'),
  },
  computed: {
    ...mapState(['officialWebsite'])
  },
  data() {
    return {
      imgLoad: false,
      currentHover: 1,

      warp2Content: {
        active: 0,
        list: [
          {
            id: 1,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-01-2.mov',
            title: '图像处理',
            text: '结合图像处理技术，生成符合要求的图片。对于照片风格处理和图像增强，利用其深度学习算法，快速处理照片，达到美化和增强的目的'
          },
          {
            id: 2,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-02-2.mov',
            title: '视频处理',
            text: '利用其自然语言处理技术合成语音，生成数字人播报。您可以输入任何需要播报的内容，无论是新闻、广告还是其他文本，都可以快速生成数字人播报，此外结合图像处理技术，将视频转化为漫画风格的图像。'
          },
          {
            id: 3,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-03-2.mov',
            title: '3D建模',
            text: '根据输入的文字描述，快速生成3D场景和角色，并且可以通过多张照片，进行小物体3D模型重建，从而快速构建虚拟世界。这种技术可以大大缩短开发时间，提高开发效率。'
          },
          {
            id: 4,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-04-2.mov',
            title: '智能客服',
            text: '结合NLP技术实现智能交互，通过声音输入实现文本生成，通过文本输入实现声音生成。这种技术可以广泛应用于智能助手、智能客服等领域，提高智能化水平，提供更好的用户体验。'
          }
        ]
      },
      courseProducts: [
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-01.png',
          sub: '游戏',
          text: '游戏 NPC 人物生成、虚拟场景和关卡生成创作捕捉灵感，创新互动形式，NPC 逻辑和剧情生成，数字资产生成，AI Bot 等'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-02-2.jpg',
          sub: '电商',
          text: '数字人直播带货，VR/AR 商品展示商品 3D 模型、虚拟主播，虚拟货场构建，通过和 AR、VR等新技术的结合，实现视听等多感官交互的沉浸式购物体验'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-03.png',
          sub: '媒体与影视',
          text: 'AI助力视频剧本创作，打造虚拟偶像 IP影视剧本创作灵感激发，角色和场景创作空间扩展，影视后期制作视频剪辑，拓展创作空间，提升作品质量；娱乐趣味性图像或音视频生成、打造虚拟偶像、开发C端用户数字化身等'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-04-2.png',
          sub: '广告与传媒 ',
          text: '创意和素材生成，AI 文字辅助创作，AI 绘画写稿机器人、采访助手、视频字幕生成、语音播报、视频锦集、人工智能合成主播等，人机协同生产，推动媒体融合'
        }
      ],
      setTime: null,
    }
  },
  mounted() {
    new this.$wow.WOW().init()
    setTimeout(()=>{
      this.hanldeClick(0)
    },2000)
  },
  methods: { 
    hanldeClick(index) {
      // if(index === this.warp2Content.active) return
      this.$refs['progressBar'+this.warp2Content.active][0].style.width = '0%'
      clearInterval(this.setTime)
      this.warp2Content.active = index
      let dom = this.$refs['video'+this.warp2Content.active][0]
      if(!dom) return
      dom.currentTime = 0
      dom.play()
      this.timeProgressBar(this.warp2Content.active, parseInt(dom.duration) * 1000)
    },
    
    timeProgressBar(index, time) {
      this.$refs['progressBar'+index][0].style.width = '0%'
      let times = time / 1000
      let tc = time / 1000
      let w = 0
      this.setTime = setInterval(() => {
        tc += times
        w += time / 10000 / times
        try {
          this.$refs['progressBar'+index][0].style.width = w+'%'
          if(tc >= time) {
            clearInterval(this.setTime)
            if(index === 3) {
              this.hanldeClick(0)
            } else {
              this.hanldeClick(index+1)
            }
          }
        } catch (e) {
          clearInterval(this.setTime)
        }
      }, times)
    },

    handleLoad (){
      this.imgLoad = true;
    },

  }
}
</script>

<style lang="scss">
html,body {
  background: #0B0E17!important;
}
</style>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
  color: #969799 !important;
}
.app-box {
  background: #0B0E17;
  min-width: 1376px;
}
.v-card {
  background: #0B0E17;
}
ul {
  margin: 0;
  padding: 0;
}

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text {
    background: #0B0E17;
    padding: 0 !important;
    height: 100%;
  }
  .logo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 160px !important;
    cursor: pointer;
    z-index: 9;
  }
  .banner-text {
    height: 100%;
    width: 430px;
    margin-left: 15%;
    position: absolute;
    top: 40%;
    // transform: translate(0, -50%);

    .tit {
      p {
        height: 80px;
        line-height: 80px;
        font-size: 80px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 900;
        color: #FFFFFF;
      }
    }
    .text {
      margin-top: 20px;
      font-size: 20px;
      white-space: pre-wrap;
      line-height: 30px;
    }
    p {
      color: #FFFFFF;
      margin: 0;
    }
  }


  
}


.public-titles {
  height: 100px;
  margin: 100px auto 0px;
  position: relative;
  text-align: center;
  padding-top: 0;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  .tit-z {
    height: 36px;
    font-size: 36px;
    line-height: 36px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #FFFFFF;
    padding: 0 25px;
  }
  div {
    display: inline;
    display: flex;
    align-items: center;
    span:nth-of-type(1) {
      width: 75px;
      height: 1px;
      background: #BDC0CD;
      border-radius: 1px 1px 1px 1px;
    }
    span:nth-of-type(2) {
      width: 7px;
      height: 7px;
      background: #BDC0CD;
      border-radius: 7px;
      margin-right: 7px;
    }
    span:nth-of-type(3) {
      width: 5px;
      height: 5px;
      background: #BDC0CD;
      border-radius: 5px;
      margin-right: 7px;
    }
    span:nth-of-type(4) {
      width: 3px;
      height: 3px;
      background: #BDC0CD;
      border-radius: 3px;
    }
    &:nth-of-type(2) {
      transform: rotateZ(180deg);
    }
  }
  p {
    margin-bottom: 0;
  }

}

.app-warp {
  width: 100%;
}

.warp1 {
  text-align: center;
  img {
    height: 370px;
  }
}


.warp2 {
  max-width: 1200px;
  margin: 100px auto 0;
  height: 720px;
  .video-text {
    width: 1200px;
    // height: 100%;
    // height: 675px;
    height: 720px;
    box-sizing: border-box;
    position: relative;
    border-radius: 10px 10px 0 0 !important;
    li {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      border-radius: 10px 10px 0 0 !important;
    }
    video {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0 !important;

    }
    .text {
      position: absolute;
      width: 100%;
      bottom: 0px;
      padding: 0 400px 60px;
      left: 50%;
      transform: translate(-50%, 0%);
      // background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 27%, #000000 100%);
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 21%, rgba(0,0,0,0.55) 43%, #000000 100%);
      p:nth-of-type(1) {
        height: 52px;
        font-size: 36px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #FFFFFF;
        line-height: 52px;
      }
      p:nth-of-type(2) {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
      }
      p {
        text-align: center;
      }
    }
    .active {
      display: block;
    }
  }
  .controllo-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    li {
      width: 24%;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(255,255,255,0.7);
      line-height: 20px;
      position: relative;
      cursor: pointer;
      .progress-bar {
        position: absolute;
        z-index: 9;
        bottom: 0;
        width: 100%;
        display: block;
        height: 2px;
        background: #43444D;
        transform-origin: 0;
        span {
          display: block;
          height: 2px;
          width: 0%;
          background: #fff;
        }
      }
    }
    .active {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 20px;
    }
  }

}

.warp3 {
  margin-bottom: 100px;
  .warp-box {
    margin: 0 auto;
    // width: 1200px;
    width: 100%;
    height: 480px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    .coverleft{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 15px;
      width: 540px;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      padding: 0 30px;
      box-sizing: border-box;
      p:nth-of-type(1) {
        height: 43px;
        font-size: 30px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #FFFFFF;
        line-height: 43px;
      }
      p:nth-of-type(2) {
        width: 280px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        transition: all 0.48s;
        max-height: 0;
        overflow: hidden;
      }
    }
    .coverleft4{
      border: 1px solid #9F9F9F !important;
    }
    .c-item-hover{
      width: 540px;
      .c-item-cover {
        width: 100%;
      }
      .c-item-cover p:nth-of-type(2) {
        max-height: 100px;
      }
      .coverleft {
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 55%, rgba(0,0,0,0.7) 100%) !important;
      }
      .coverleft3 {
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 55%, rgba(0,0,0,0.8) 100%) !important;
      }
      .coverleft4{
        border: 1px solid  rgba(255, 255, 255, 0.2) !important;
      }
    }
    .c-item-1{
      margin-right: 20px;
      background-position: 0;
      overflow: hidden;
      position: relative;
      height: 480px;
      border-radius: 15px;
      transition: width .4s;
      transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
      // width: 240px;
      width: 200px;
      transition: all .4s;
      transition-timing-function: ease;
    }
    .item4 {
      border: 1px solid  rgba(255, 255, 255, 0.2) !important;
      .coverleft4{
        border: 0px solid  rgba(255, 255, 255, 0.2) !important;
      }
    } 
    .c-item-cover {
      // width: 200px;
      width: 540px;
      height: 480px;
      border-radius: 15px;
      //opacity: .6;
      transition: all .4s;
      display: flex;
      transition-timing-function: ease;
      position: relative;
    }
  }
}


</style>